import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getCurrentMemberAccount } from '@wix/ambassador-loyalty-v1-account/http';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';

export async function getLoyaltyAccount(flowAPI: PlatformControllerFlowAPI): Promise<LoyaltyAccount> {
  const { httpClient } = flowAPI;

  try {
    const response = await httpClient.request(getCurrentMemberAccount({}));
    return response.data.account!;
  } catch (error) {
    // When user has no points loyalty account doesn't exist
    if (error instanceof Error && error.message.includes('404')) {
      return {};
    }

    throw error;
  }
}
