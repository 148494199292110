import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { listRewards } from '@wix/ambassador-loyalty-v1-reward/http';
import { Reward as LoyaltyReward } from '@wix/ambassador-loyalty-v1-reward/types';

export async function getLoyaltyRewards(flowAPI: PlatformControllerFlowAPI): Promise<LoyaltyReward[]> {
  const { httpClient } = flowAPI;

  const response = await httpClient.request(listRewards({}));

  return response.data.rewards!;
}
