import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { listEarningRules } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/http';
import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';

export async function getLoyaltyEarningRules(flowAPI: PlatformControllerFlowAPI): Promise<LoyaltyEarningRule[]> {
  const { httpClient } = flowAPI;

  const response = await httpClient.request(listEarningRules({}));

  return response.data.earningRules!;
}
