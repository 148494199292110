import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getCheckout as getCheckoutRequest } from '@wix/ambassador-ecom-v1-checkout/http';
import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';

export async function getCheckout(flowAPI: PlatformControllerFlowAPI, checkoutId: string): Promise<Checkout> {
  const { httpClient } = flowAPI;

  const response = await httpClient.request(
    getCheckoutRequest({
      id: checkoutId,
    }),
  );

  return response.data.checkout!;
}
